body{
    font-family: "Open Sans", sans-serif;
    background-color: black;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    margin: 0;
}

.App{
    background-color: black;
}

.Filter{
    min-width: 100vh;
    min-height: 100vh;
}

.filter-box{
    border-radius: 25px;
    background-color:#000;
    padding: 40px;
    margin: 40px;
}

.dropdown{
    background-color:#343A40;
    width: 100%;
    border-radius: 5px;
    font-family: Arial, Helvetica, sans-serif;
}

.card-container{
    background-color:#000;
}

.card-img{
    max-width: 150%;
    height: 75%;
}